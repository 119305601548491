import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/localIndustryTalentDevelopment_img.jpg";
import SubTab from "../SubTab/SubTab";

const LocalIndustryTalentDevelopment = () => {
  const category = "localIndustryTalentDevelopment";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}인재 양성 사업
            </div>
            <div className="banner_main_text">인재 양성 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">인재양성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div className="navi_box human" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">지역산업맞춤형 인력양성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  지역산업맞춤형 인력양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/koreanQuickStartProgram")}
                >
                  한국형 퀵스타트프로그램 사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/smeResearchTalentSupport")}
                >
                  중소기업 연구인력 현장맞춤형 양성지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamIndustryAcademiaProgram")
                  }
                >
                  충남산학융합형 인력양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/youthVentureProgram")}
                >
                  청년도전지원사업
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="local-img1"></div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="business_content_box">
                <div className="business_content">
                  <div className="business_content_title">
                    산업
                    <br />
                    부분
                  </div>
                  <div className="business_content_text_box">
                    <div className="content_text">
                      충남 서북부지역의 공동훈련센터 지정
                    </div>
                    <div className="content_text">
                      충남 서북부지역의 지역•산업 맞춤형 인력양성 프로그램을
                      통해 지역 기반 산업인 자동차 부품, 철강(금속), 기계산업
                      분야에 대한 맞춤형 전문 인력 양성을 통해 지역
                      중소•중견기업 경쟁력 강화
                    </div>
                  </div>
                </div>
                <div className="business_content">
                  <div className="business_content_title">
                    일자리
                    <br />
                    창출
                  </div>
                  <div className="business_content_text_box">
                    <div className="content_text">
                      지역 산업 수요에 적합한 인력 양성을 위해 NCS 기반의 실무
                      맞춤형 훈련교육 프로그램을 선정하여 중소 • 중견기업의 현장
                      실무 중심의 우수 인력을 공급할 수 있는 선순환적 인력
                      지원의 일자리 창출
                    </div>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="propulsion_img_box">
                <div className="local-img2"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업 목표</div>
              </div>
              <div className="final_result_box">
                <div className="project-result-box">
                  <div className="table-container">
                    <table className="project-result-table">
                      <thead>
                        <tr>
                          <th colSpan={2}>협약기업</th>
                          <th colSpan={2}>훈련인원</th>
                          <th rowSpan={2}>훈련생만족도</th>
                          <th colSpan={3}>채용예정자 훈련</th>
                        </tr>
                        <tr>
                          <th style={{ width: "calc((100%) / 8)" }}>기업수</th>
                          <th style={{ width: "calc((100%) / 8)" }}>
                            참여율(%)
                          </th>
                          <th style={{ width: "calc((100%) / 8)" }}>연인원</th>
                          <th style={{ width: "calc((100%) / 8)" }}>
                            참여율(%)
                          </th>
                          <th style={{ width: "calc((100%) / 8)" }}>
                            연인원(명)
                          </th>
                          <th style={{ width: "calc((100%) / 8)" }}>
                            취업률(%)
                          </th>
                          <th style={{ width: "calc((100%) / 8)" }}>
                            수료율(%)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>55</td>
                          <td>90%</td>
                          <td>500</td>
                          <td>25%</td>
                          <td>90점 이상</td>
                          <td>40</td>
                          <td>80%</td>
                          <td>90%</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">최종 성과</div>
              </div>
              <div className="final_result_box">
                <div className="result_title_box">협약기업 확산 방향</div>
                <div
                  className="result_content_box local"
                  style={{ justifyContent: "center", margin: "40px 0"}}
                >
                  <div className="result_inner_box">
                    <div className="result_content_item">
                      최종
                      <br />
                      목표
                    </div>
                  </div>
                  <div className="result_inner_box local">
                    <div className="content_item_text">
                      산업단지 고용환경개선
                    </div>
                    <div
                      className="content_item_box"
                      style={{ width: "480px" }}
                    >
                      <div className="content_item_round"></div>
                      <div className="content_item first">
                        충남 서북부권 산업단지 인력양성의 메카
                      </div>
                      <div className="content_item second">
                        지역 기업과 함께 더 잘하는 인력양성
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="result_content_box local2"
                  style={{ justifyContent: "center", margin: "20px 0" }}
                >
                  <div className="result_inner_box local2" style={{ width: "100%" }}>
                    <div className="inner_item_box first">
                      <div className="item_title">향상 과정</div>
                      <div className="item_content">
                        산업단지 수요에 따른
                        <br />
                        신규 훈련과정 확산 개설
                      </div>
                    </div>
                    <div className="inner_item_box second">
                      <div className="item_title">양성 과정</div>
                      <div className="item_content">
                        산업단지 신규 인력의
                        <br />
                        안정적 공급
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="result_content_box"
                  style={{ justifyContent: "center", margin: "40px 0" }}
                >
                  <div className="result_inner_box last" style={{ width: "100%" }}>
                    <div
                      className="inner_item_box division"
                      style={{ backgroundColor: "#e6e4e5" }}
                    >
                      <div className="item_content title-none">
                        당진 • 서산권 산업단지
                        <br />
                        인력양성거점구축
                        <p>준비단계(2017~2021)</p>
                      </div>
                    </div>
                    <div className="content_icon">
                      <i className="icon_arrow_2"></i>
                    </div>
                    <div
                      className="inner_item_box division"
                      style={{ backgroundColor: "#e6e4e5" }}
                    >
                      <div className="item_content title-none">
                        충남지역
                        <br />
                        산업단지로의 확산
                        <p>확산단계(2022~2025)</p>
                      </div>
                    </div>
                    <div className="content_icon">
                      <i className="icon_arrow_2"></i>
                    </div>
                    <div
                      className="inner_item_box division"
                      style={{
                        backgroundColor: "#f4e7c5",
                        border: "2px solid #e45827",
                      }}
                    >
                      <div className="item_content title-none">
                        충남 산업단지
                        <br />
                        인력양성 거점기관 확산
                        <p>안정화 단계(2026~2030)</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalIndustryTalentDevelopment;
