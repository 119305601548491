import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/chungnamInnovationCluster_img.jpg";
import SubTab from "../SubTab/SubTab";

const ChungnamInnovationCluster = () => {
  const category = "ChungnamInnovationCluster";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}기업 지원 사업
            </div>
            <div className="banner_main_text">기업 지원 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">기업지원사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div
              className="navi_box enterprise"
              onClick={() => handleSelect(2)}
            >
              <div className="navi_main_text">충남지역혁신클러스터</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  충남지역혁신프로젝트 사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationCluster")
                  }
                >
                  충남지역혁신클러스터
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/carbonNeutralInfrastructure")
                  }
                >
                  탄소중립실증인프라구축사업
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="sub_main_area founded "> */}
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="business_title">
              <div className="cluster-img1"></div>
            </div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="business_img_box">
                <div className="cluster-img2"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="propulsion_img_box">
                <div className="cluster-img3"></div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">최종 목표</div>
              </div>
              <div className="final_result_box">
                <p className="cluster-txt">
                  · 수소산업 혁신파이프라인 최적화를 통한 수소기반 혁신자원 확대
                  및 강화
                </p>
                <p className="cluster-txt">
                  · 충남 수소 핵심기술 선도, 글로벌 혁신 거점연계 기술 사업화
                  기회 확대
                </p>
                <p className="cluster-txt">
                  · 지속가능한 혁신거점 체계 확립을 통한 지식의 구축(DB)과
                  신속한 확산
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChungnamInnovationCluster;
