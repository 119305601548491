import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import enterprise_img from "../../../../assets/image/chungnamIndustryAcademiaProgram_img.jpg";
import SubTab from "../SubTab/SubTab";

const ChungnamIndustryAcademiaProgram = () => {
  const category = "chungnamIndustryAcademiaProgram";
  const [select, setSelect] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (num) => {
    if (select === num) {
      setSelect(0);
    } else {
      setSelect(num);
    }
  };
  const moveBoard = (cate) => {
    navigate(`/board/${cate}`, { state: { cate: cate } });
  };
  const movePage = (path) => {
    navigate(path);
  };

  return (
    <div className="sub_wrap">
      <div className="sub_back">
        <div className="sub_banner_area">
          <div className="sub_banner_back">
            <div className="navi_text">
              <div className="home_icon_sub"></div>
              {"> "}사업분야{" > "}인재 양성 사업
            </div>
            <div className="banner_main_text">인재 양성 사업</div>
          </div>
        </div>
        <div className="navi_area">
          <div className="navi_back">
            <div
              className="navi_box business_field"
              onClick={() => handleSelect(1)}
            >
              <div className="navi_main_text">인재양성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 1 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() => movePage("/fusionSupportProgram")}
                >
                  산학융합지구조성사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamInnovationProject")
                  }
                >
                  기업지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  인재양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/dangjinStartupSupport")}
                >
                  창업육성사업
                </div>
              </div>
            </div>
            <div className="navi_box human" onClick={() => handleSelect(2)}>
              <div className="navi_main_text">충남산학융합형 인력양성사업</div>
              <div className="navi_arrow"></div>
              <div className={`navi_select_box ${select === 2 && "active"}`}>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/localIndustryTalentDevelopment")
                  }
                >
                  지역산업맞춤형 인력양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/koreanQuickStartProgram")}
                >
                  한국형 퀵스타트프로그램 사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/smeResearchTalentSupport")}
                >
                  중소기업 연구인력 현장맞춤형 양성지원사업
                </div>
                <div
                  className="select_row"
                  onClick={() =>
                    movePage("/business/chungnamIndustryAcademiaProgram")
                  }
                >
                  충남산학융합형 인력양성사업
                </div>
                <div
                  className="select_row"
                  onClick={() => movePage("/business/youthVentureProgram")}
                >
                  청년도전지원사업
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub_main_area temporary">
          <SubTab category={category} />
          <div className="sub_main_back">
            <div className="academia-img1"></div>
            <div className="vision_container">
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업내용</div>
              </div>
              <div className="business_text_box">
                <div className="business_txt">
                  학교와 기업 담당자 간 인적 네트워크를 형성하여 기업이 요구하는
                  맞춤형 교육 과정 개설 및 운영으로 <br />
                  실질적인 취업 역량 강화 지원 프로그램 운영
                </div>
                <div className="business_txt">
                  4차 산업혁명 기술을 활용한 체험형 학습과 진로 체험의 기회 제공
                </div>
                <div className="business_txt">
                  취업시장 진출을 위한 단계별 진로 설계로 구직자의 눈높이에 맞춘
                  교육프로그램과 인성 및 비즈니스 매너교육 운영
                </div>
                <div className="business_txt">
                  지역 내 학교, 기업 및 유관기관과의 협력을 통한 인력양성
                  네트워크 및 취업 지원 체계 구축
                </div>
                <div className="business_txt">
                  창업 활성화 지원 프로그램 운영으로 미래 일자리 창출에 기여할
                  수 있는 역량 강화
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">추진체계</div>
              </div>
              <div className="final_result_box">
                <div className="academia-floor-wrap">
                  <div className="academia-floor-item">
                    <div className="academia-floor-item-head">계획 수립</div>
                    <div className="academia-floor-item-body">
                      교육과정별
                      <br />
                      세부계획(안) 수립
                    </div>
                    <div className="academia-floor-item-footer">
                      <p>4월 중순</p>
                    </div>
                  </div>
                  <div className="content_icon">
                    <i className="icon_arrow_4"></i>
                  </div>
                  <div className="academia-floor-item">
                    <div className="academia-floor-item-head">신청•접수</div>
                    <div className="academia-floor-item-body">
                      교육대상 학교 모집
                    </div>
                    <div className="academia-floor-item-footer">
                      <p>5월 초</p>
                    </div>
                  </div>
                  <div className="content_icon">
                    <i className="icon_arrow_4"></i>
                  </div>
                  <div className="academia-floor-item">
                    <div className="academia-floor-item-head">계획 수립</div>
                    <div className="academia-floor-item-body">
                      참여학교 선정
                    </div>
                    <div className="academia-floor-item-footer">
                      <p>5월 중순</p>
                    </div>
                  </div>
                  <div className="content_icon none">
                    <i className="icon_arrow_4 none"></i>
                  </div>
                </div>
                <div className="academia-floor-wrap">
                  <div className="academia-floor-item">
                    <div className="academia-floor-item-head">교육운영</div>
                    <div className="academia-floor-item-body">
                      교육과정별 진행
                    </div>
                    <div className="academia-floor-item-footer">
                      <p>5월 ~ 11월</p>
                    </div>
                  </div>
                  <div className="content_icon">
                    <i className="icon_arrow_4"></i>
                  </div>
                  <div className="academia-floor-item">
                    <div className="academia-floor-item-head">사업종료</div>
                    <div className="academia-floor-item-body">
                      참여학교/학생 평가
                    </div>
                    <div className="academia-floor-item-footer">
                      <p>12월</p>
                    </div>
                  </div>
                  <div className="content_icon">
                    <i className="icon_arrow_4"></i>
                  </div>
                  <div className="academia-floor-item">
                    <div className="academia-floor-item-head">
                      결과보고 및 정산
                    </div>
                    <div className="academia-floor-item-body">
                      사업 실적보고 및<br />
                      사업비 정산
                    </div>
                    <div className="academia-floor-item-footer">
                      <p>25년 1~2월</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">사업 목표</div>
              </div>
              <div className="objective_box">
                <div
                  className="objective_content_box academia"
                  style={{ padding: "0", alignItems: "center" }}
                >
                  <div className="academia_objective_content_left">
                    <div className="academia_objective_item">
                      지역 청년구직자들의 취업 경쟁력 제고
                    </div>
                    <div className="academia_objective_item">
                      자기개발 및 스펙 업 지원을 통한 <br />
                      청년 구직자들의 자존감 고취
                    </div>
                    <div className="academia_objective_item">
                      지역 기업의 인력난 해소 및 선순환 체계 구축
                    </div>
                  </div>
                  <div className="content_icon">
                    <i
                      className="icon_arrow_4"
                      style={{ width: "80px", height: "80px" }}
                    ></i>
                  </div>
                  <div className="academia_objective_content_right">
                    <div className="academia_objective_item">
                      지역 인재
                      <br />
                      외부 유출방지
                    </div>
                    <div className="academia_objective_item">
                      지역 경제활성화
                    </div>
                  </div>
                </div>
              </div>
              <div className="vision_title_box">
                <div className="logo_box">
                  <div className="logo_img"></div>
                </div>
                <div className="title_text">최종 성과</div>
              </div>
              <div className="final_result_box">
                <div className="final_result_head">
                  10개 과정(맞춤형 성장지원 인력양성 2개, 취업역량 강화 지원
                  6개, 창업 활성화 지원 2개), 72개교, 2,481명 교육운영
                </div>
                <div className="final_result_body">
                  <div className="final_result_item1">
                    <div className="item_head">취업역량 강화지원</div>
                    <div className="item_body">
                      <div className="item_txt">
                        취업 역량강화 프로그램:5개교(137명)
                      </div>
                      <div className="item_txt">
                        직장예절과 비즈니스 매너교육:5개교(279명)
                      </div>
                      <div className="item_txt">
                        직무 적성검사 및 커리어 교육:5개교(350명)
                      </div>
                      <div className="item_txt">
                        현장맞춤형 교육:2개교(82명)
                      </div>
                      <div className="item_txt">
                        고등학교 취업부장&기업 인사담당자
                        <br />
                        <p
                          style={{
                            paddingTop: "10px",
                            fontFamily: "Pretendard_b",
                          }}
                        >
                          간담회:1회(35명)
                        </p>
                      </div>
                      <div className="item_txt">취업박람회 개최:1회(100명)</div>
                    </div>
                  </div>
                  <div className="final_result_item2">
                    <div className="item_head">맞춤형 성장지원 인력양성</div>
                    <div className="item_body">
                      <div className="item_txt">
                        4차 산업기술 진로 체험교육:10개교(334명)
                      </div>
                      <div className="item_txt">
                        인성 예절 교육:7개교(278명)
                      </div>
                    </div>
                  </div>
                  <div className="final_result_item3">
                    <div className="item_head">창업활성화 지원</div>
                    <div className="item_body">
                      <div className="item_txt">
                        창업 캠프 프로그램:11개교(264명)
                      </div>
                      <div className="item_txt">
                        창업 특강 운영:17개교(653명)
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChungnamIndustryAcademiaProgram;
